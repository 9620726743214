import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { FreeMode, Navigation, Thumbs, Pagination, Mousewheel } from "swiper";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import Button from '@mui/material/Button';
import Loader from '../../Components/Loader'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import PhoneIcon from '@mui/icons-material/Phone';
import HelpIcon from '@mui/icons-material/Help';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Host from '../../Components/Host';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import IconButton from '@mui/joy/IconButton';

function ProductImages({ productData, productFetching, isLoading, isFetching, productVarData, isVarLoading }) {
  const { languageCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const { t } = useTranslation()


  const thisImages = productData?.images
  const thisImage = productData?.image
  // if (productVarData?.images) {
  //   thisImages = productVarData?.images
  // }
  // if (productVarData?.image) {
  //   thisImage = productVarData?.image
  // }

  const [imagesNavSlider, setImagesNavSlider] = useState(null);

  const handleError = (e) => {
    e.target.src = 'https://cdn.ebtida.com/1705315901965-Image-not-found.jpg';
  };


  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredImg, setIsHoveredImg] = useState('');

  const handleMouseEnter = (image) => {
    setIsHovered(true);
    setIsHoveredImg(image)
  }
  const handleMouseLeave = (image) => {
    setIsHovered(false);
    setIsHoveredImg(image)
  }

  return (
    <>
      <div className="slider__images">
        <section className="slider slider-hide-m" style={{ display: 'flex' }}>
          <div className='product-image-thumb'>
            <div className="slider__prev" style={{ marginBottom: '5px' }}>
              {thisImages?.split(',')?.length > 6 &&
                <Button variant='outlined' style={{ border: 'none', height: '25px', fontWeight: 400, color: '#444', textTransform: 'capitalize' }}>
                  <KeyboardArrowUpIcon />
                </Button>
              }
            </div>
            <div className="slider__thumbs">
              <Swiper
                onSwiper={setImagesNavSlider}
                direction="vertical"
                spaceBetween={5}
                slidesPerView={6}
                navigation={{
                  nextEl: ".slider__next",
                  prevEl: ".slider__prev"
                }}
                className="swiper-container1"
                breakpoints={{
                  0: {
                    direction: "horizontal"
                  },
                  768: {
                    direction: "vertical"
                  }
                }}
                modules={[Navigation, Thumbs]}
              >

                {isLoading ?
                  <>
                    <SwiperSlide className="slider__image_section">
                      <div className="slider__image">
                        <Skeleton variant="rect" style={{ height: '65px', width: '100%' }} />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="slider__image_section">
                      <div className="slider__image">
                        <Skeleton variant="rect" style={{ height: '65px', width: '100%' }} />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="slider__image_section">
                      <div className="slider__image">
                        <Skeleton variant="rect" style={{ height: '65px', width: '100%' }} />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="slider__image_section">
                      <div className="slider__image">
                        <Skeleton variant="rect" style={{ height: '65px', width: '100%' }} />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className="slider__image_section">
                      <div className="slider__image">
                        <Skeleton variant="rect" style={{ height: '65px', width: '100%' }} />
                      </div>
                    </SwiperSlide>
                  </>
                  :
                  <>
                    {thisImages ?
                      <>
                        {thisImages?.split(',')?.map((slide, index) => {
                          return (
                            <SwiperSlide className='slider__image_section' key={index}>
                              <div
                                onMouseEnter={(() => handleMouseEnter(slide))}
                                onMouseLeave={(() => handleMouseLeave(''))}
                                className="slider__image"
                                style={{ cursor: 'pointer' }}>
                                {/* <img src={slide} alt="" /> */}
                                <LazyLoadImage
                                  style={{ filter: isFetching && 'grayscale(1)' }}
                                  delayTime={100}
                                  effect="blur"
                                  src={slide}
                                  onError={handleError}
                                />
                              </div>
                            </SwiperSlide>
                          );
                        })}
                      </> :
                      <SwiperSlide className="slider__image_section">
                        <div className="slider__image">
                          {/* <img src={productData?.image} /> */}
                          <LazyLoadImage
                            delayTime={100}
                            effect="blur"
                            src={thisImage}
                            onError={handleError}
                          />
                        </div>
                      </SwiperSlide>
                    }
                  </>
                }
              </Swiper>
            </div>
            <div className="slider__next">
              {thisImages?.split(',')?.length > 6 &&
                <Button variant='outlined' style={{ border: 'none', height: '25px', fontWeight: 400, color: '#444', textTransform: 'capitalize' }}>
                  <KeyboardArrowDownIcon />
                </Button>
              }
            </div>
          </div>
        </section>

        <Swiper
          style={{ background: '#fff', borderRadius: '10px', margin: '0 5px', width: '100%', position: 'relative' }}
          thumbs={{ swiper: imagesNavSlider }}
          direction="horizontal"
          slidesPerView={1}
          spaceBetween={0}
          navigation={{
            nextEl: ".slider__next",
            prevEl: ".slider__prev"
          }}
          breakpoints={{
            0: {
              direction: "horizontal"
            },
            768: {
              direction: "horizontal"
            }
          }}
          className="mySwiper pp-main-slider"
          modules={[Navigation, Thumbs]}
        >

          <div className="slider__prev pp-main-navigation-back">
            {thisImages?.split(',')?.length > 1 &&
              <IconButton size='sm'>
                {!isLoading &&
                  <ArrowBackIosNewOutlinedIcon style={{ fontSize: '1.1rem' }} />
                }
              </IconButton>
            }
          </div>

          {isFetching ?
            <SwiperSlide className="Dashboard-books-card">
              <div className="img-list-card">
                <div className='img-list-img' style={{ display: 'flex', alignItems: 'center' }}>
                  <Loader />
                </div>
              </div>
            </SwiperSlide>
            :
            <>
              {thisImages ?
                <>
                  {thisImages?.split(',')?.map((slide, index) => {
                    return (
                      <SwiperSlide className="Dashboard-books-card" key={index}>

                        <div className="img-list-card">
                          {/* <img className="img-list-img" src={slide} /> */}
                          {isHovered ?
                            <LazyLoadImage
                              className="img-list-img"
                              delayTime={100}
                              effect="blur"
                              src={isHoveredImg}
                              onError={handleError}
                            />

                            :
                            <LazyLoadImage
                              className="img-list-img"
                              delayTime={100}
                              effect="blur"
                              src={slide}
                              onError={handleError}
                            />
                          }
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </> :
                <SwiperSlide className="Dashboard-books-card">
                  <div className="img-list-card">
                    {/* <img className="img-list-img" src={productData?.image} /> */}
                    <LazyLoadImage
                      className="img-list-img"
                      delayTime={100}
                      effect="blur"
                      src={thisImage}
                      onError={handleError}
                    />
                  </div>
                </SwiperSlide>
              }
            </>
          }

          <div className="slider__next pp-main-navigation-next">
            {thisImages?.split(',')?.length > 1 &&
              <IconButton size='sm'>
                {!isLoading &&
                  <ArrowForwardIosOutlinedIcon style={{ fontSize: '1.1rem' }} />
                }
              </IconButton>
            }
          </div>

        </Swiper>

      </div>

      <div style={{ padding: '10px', background: '#f7f7f7', borderBottom: '1px solid #eee', borderRadius: '4px', marginBottom: '10px', marginTop: '10px' }}>
        <div className='ftr-time-section' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
          <div className='ftr-time-card pp-hide-m' style={{ display: 'flex', alignItems: 'center' }}>
            <HelpIcon style={{ fontSize: '22px', color: '#00000059' }} />
            <div className='ftr-time-item' style={{ fontSize: '14px', margin: '0px 10px' }}>
              <div style={{ fontSize: '13px', lineHeight: 1.2 }}>Need help?<br /><small>8AM to 10PM Dubai Time (7 Days a Week)</small></div>
            </div>
          </div>
          <div>
            <div className='ftr-time-card' style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center' }}>
              {/* <div style={{ fontSize: '14px', fontWeight: 500, margin: '0 10px' }}>{t('support')}:</div> */}

              <div className='ftr-time-item' style={{ display: 'flex', alignItems: 'center', margin: '0 5px' }}>
                <SupportAgentOutlinedIcon style={{ fontSize: '16px' }} />
                <span style={{ margin: '0 7px', fontSize: '13px' }}>{t('Live Chat')}</span>
              </div>
              <div className='ftr-time-item' style={{ display: 'flex', alignItems: 'center', margin: '0 5px' }}>
                <MailOutlineIcon style={{ fontSize: '16px' }} />
                <span style={{ margin: '0 7px', fontSize: '13px' }}>
                  <a href={`mailto:${Host?.email}`} target="_blank" >
                    {t('email')}
                  </a>
                </span>
              </div>
              <div className='ftr-time-item' style={{ display: 'flex', alignItems: 'center', margin: '0 5px' }}>
                <PhoneIcon style={{ fontSize: '16px' }} />
                <span style={{ margin: '0 7px', fontSize: '13px' }}>{Host?.phone}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductImages;
