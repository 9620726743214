import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import './index.scss';
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button';
import Host from '../../Components/Host';
import ReCAPTCHA from "react-google-recaptcha";
import { useFindOrderMutation, useVerifyMutation } from '../../services/track_order';
import OtpInput from "react-otp-input";
import Loader from '../../Components/Loader';
import HelmetComponent from '../../Components/HelmetComponent';
import { Tag } from "antd";
import IconButton from '@mui/joy/IconButton';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LocalShippingRoundedIcon from '@mui/icons-material/LocalShippingRounded';
import Stepper from '@mui/joy/Stepper';
import Step, { stepClasses } from '@mui/joy/Step';
import StepIndicator, { stepIndicatorClasses } from '@mui/joy/StepIndicator';
import Typography, { typographyClasses } from '@mui/joy/Typography';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import LocalMallIcon from '@mui/icons-material/LocalMall';
var moment = require('moment');

function TrackOrder() {
  const { countryCode, languageCode } = useSelector((state) => state.auth);
  const history = useHistory();
  const { t } = useTranslation()

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      // behavior: "smooth"
    });
  }, [])

  let fontFamilyForAll
  if (languageCode === 'ar') {
    fontFamilyForAll = 'GessTwoMedium, sans-serif'
  } else {
    fontFamilyForAll = 'Poppins, sans-serif'
  }
  const fontFamily = {
    fontFamily: fontFamilyForAll,
  }

  const [findorder, findresult] = useFindOrderMutation()
  const [verifyotp, verifyresult] = useVerifyMutation()
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 } } = useForm();
  const recaptchaRef = React.useRef();
  const [isOtpCard, setOtpCard] = useState(false);
  const [isLoginCard, setLoginCard] = useState(true);
  const [orderData, setorderData] = useState();
  const [otp, setOtp] = useState('');
  const [getToken, setToken] = useState('');

  async function loginWithOtp() {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    verifyotp({
      otp: otp,
      token: getToken,
      captcha_token: token,
      countryCode: countryCode,
      domain: Host?.domain,
      domainEmail: Host?.email
    })
  }

  async function trackOrder(data) {
    const token = await recaptchaRef.current.executeAsync();
    recaptchaRef.current.reset();
    findorder({
      order_number: data.order_number,
      email: data.email,
      captcha_token: token,
      countryCode: countryCode,
      domain: window.location.hostname || Host?.domain,
      domainEmail: Host?.email
    })
  }

  useEffect(() => {
    if (findresult?.isSuccess) {
      setToken(findresult?.data?.token)
      setLoginCard(false)
      setOtpCard(true)
    }
    if (verifyresult?.isSuccess) {
      setorderData(verifyresult?.data?.order)
      setLoginCard(false)
      setOtpCard(false)
    }
    if (!verifyresult?.isSuccess) {
      if (!findresult?.isSuccess) {
        setLoginCard(true)
      }
    }
  }, [findresult, verifyresult])


  function getDeliverydays(data, created) {

    if (Number(data) > 0) {
      return <>{moment(created).add(Number(data), 'days').format('dddd Do MMM YYYY')}</>
    } else {
      return moment(created).add(10, 'days').format('dddd Do MMM YYYY')
    }
  }

  const groupData = verifyresult?.data?.order?.order_items?.reduce((acc, obj) => {
    const key = obj.estimated_delivery;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});





  function getShippingStep(thisData) {
    const values = thisData?.split(',');
    if (values?.some(part => part.includes('arrived'))) {
      return true
    } else if (values?.some(part => part.includes('departed'))) {
      return true
    } else if (thisData.includes('out_for_delivery')) {
      return true
    } else {
      return false
    }
  }

  function getShippingStep1(thisData) {
    const values = thisData?.split(',');
    if (values?.some(part => part.includes('arrived'))) {
      return true
    } else if (values?.some(part => part.includes('departed'))) {
      return true
    } else if (thisData.includes('out_for_delivery')) {
      return true
    } else {
      return false
    }
  }

  const [isExpand, setExpand] = useState('');

  function openTracking(data) {
    setExpand(data)
  }

  const soteIds = [3, 1]
  function functionIdLink(id, pid, store_id) {
    if (soteIds?.includes(Number(store_id))) {
      return pid
    } else {
      return id
    }
  }
  function productTitle(title) {
    return title?.replace(/[^A-Za-z0-9]/g, ' ')?.replace(/[^\w\s.,]/gi, ' ')?.replace(/ /g, '-')
  }


  function getitesmData(data) {
    return (
      <div className='op-section'>
        <div className='op-card' style={{ ackground: '#fff0', border: 'none', padding: 0 }}>


          {groupData && Object?.entries(groupData).map(([group, groupItems]) => (
            <div
              key={group}
              style={{
                border: '1px solid #eee',
                borderRadius: '10px',
                padding: '15px',
                background: '#efefef3b',
                marginBottom: '15px'
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '10px' }}>

                <div style={{ fontSize: '14px', fontWeight: 500 }}>
                  Estimated Delivery by&nbsp;{getDeliverydays(group, data?.createdAt)}
                </div>
                <div style={{ fontSize: '14px', fontWeight: 500 }}>Items: {groupItems?.length}</div>
              </div>

              <div className='op-item-section' style={{ display: 'block' }}>
                {groupItems?.map((items, index) =>
                  <div key={index} style={{ padding: '15px', background: '#fff', marginBottom: '10px', borderRadius: '10px' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
                      {items.state !== 'cancelled' &&
                        <Stepper
                          className="step-card-list"
                          size="lg"
                          sx={{
                            width: '100%',
                            '--StepIndicator-size': '2rem',
                            '--Step-connectorInset': '0px',
                            [`& .${stepIndicatorClasses.root}`]: {
                              borderWidth: 2,
                            },
                            [`& .${stepClasses.root}::after`]: {
                              height: 2,
                            },
                            [`& .${stepClasses.completed}`]: {
                              [`& .${stepIndicatorClasses.root}`]: {
                                borderColor: 'primary.300',
                                // color: 'primary.300',
                              },
                              '&::after': {
                                bgcolor: 'primary.300',
                              },
                            },
                            [`& .${stepClasses.active}`]: {
                              [`& .${stepIndicatorClasses.root}`]: {
                                borderColor: 'currentColor'
                              },
                            },
                            [`& .${stepClasses.disabled} *`]: {
                              color: 'neutral.outlinedDisabledColor',
                            },
                          }}
                        >
                          <Step
                            className="step-item-list"
                            active={items.shipping_state === 'processing'}
                            completed={items.shipping_status?.split(',')?.includes('dispatched')}
                            orientation="vertical"
                            indicator={
                              <StepIndicator
                                variant={items.shipping_status?.split(',')?.includes('dispatched') ? 'solid' : 'outlined'}
                                color={items.shipping_status?.split(',')?.includes('dispatched') ? 'success' : items.shipping_state === 'processing' ? 'primary' : 'neutral'}>

                                {items.shipping_status?.split(',')?.includes('dispatched') ?
                                  <CheckCircleRoundedIcon style={{ fontSize: '20px' }} /> :
                                  <FiberManualRecordIcon className='checkthiss' style={{ fontSize: '20px' }} />
                                }
                              </StepIndicator>
                            }
                          >
                            <Typography
                              style={{ gap: '1px' }}
                              sx={{
                                textTransform: 'uppercase',
                                fontWeight: 'lg',
                                fontSize: '0.7rem',
                                letterSpacing: '0.5px',
                                gap: '2px'
                              }}
                            >
                              Processing
                            </Typography>
                          </Step>

                          <Step
                            className="step-item-list"
                            active={items.shipping_state === 'dispatched'}
                            completed={getShippingStep(items.shipping_status)}
                            orientation="vertical"
                            indicator={
                              <StepIndicator
                                variant={getShippingStep(items.shipping_status) ? 'solid' : 'outlined'}
                                color={getShippingStep(items.shipping_status) ? 'success' : items.shipping_state === 'dispatched' ? 'primary' : 'neutral'}>
                                {getShippingStep(items.shipping_status) ?
                                  <CheckCircleRoundedIcon style={{ fontSize: '20px' }} /> :
                                  <LocalMallIcon style={{ fontSize: '20px' }} />
                                }
                              </StepIndicator>
                            }
                          >
                            <Typography
                              sx={{
                                textTransform: 'uppercase',
                                fontWeight: 'lg',
                                fontSize: '0.7rem',
                                letterSpacing: '0.5px',
                              }}
                            >
                              Procuring
                            </Typography>
                          </Step>
                          <Step
                            className="step-item-list"
                            active={getShippingStep(items.shipping_status)}
                            completed={items.shipping_status?.split(',')?.includes('delivered')}
                            orientation="vertical"
                            indicator={
                              <StepIndicator
                                variant={items.shipping_status?.split(',')?.includes('delivered') ? 'solid' : 'outlined'}
                                color={items.shipping_status?.split(',')?.includes('delivered') ? 'success' : getShippingStep(items.shipping_status) ? 'primary' : 'neutral'}>
                                {items.shipping_status?.split(',')?.includes('delivered') ?
                                  <CheckCircleRoundedIcon style={{ fontSize: '20px' }} /> :
                                  <LocalShippingRoundedIcon style={{ fontSize: '20px' }} />
                                }
                              </StepIndicator>
                            }
                          >
                            <Typography
                              sx={{
                                textTransform: 'uppercase',
                                fontWeight: 'lg',
                                fontSize: '0.7rem',
                                letterSpacing: '0.5px',
                              }}
                            >
                              Shipping
                            </Typography>
                          </Step>
                          <Step
                            className="step-item-list"
                            active={items.shipping_status?.split(',')?.includes('delivered')}
                            completed={items.shipping_status?.split(',')?.includes('delivered')}
                            orientation="vertical"
                            indicator={
                              <StepIndicator
                                variant={items.shipping_status?.split(',')?.includes('delivered') ? 'solid' : 'outlined'}
                                color={items.shipping_status?.split(',')?.includes('delivered') ? 'primary' : 'neutral'}>
                                {items.shipping_status?.split(',')?.includes('delivered') ?
                                  <CheckCircleRoundedIcon style={{ fontSize: '20px' }} /> :
                                  <CheckCircleRoundedIcon style={{ fontSize: '20px' }} />
                                }
                              </StepIndicator>
                            }
                          >
                            <Typography
                              sx={{
                                textTransform: 'uppercase',
                                fontWeight: 'lg',
                                fontSize: '0.7rem',
                                letterSpacing: '0.5px',
                              }}
                            >
                              Delivered
                            </Typography>
                          </Step>
                        </Stepper>
                      }
                    </div>
                    <div className='op-item-card' style={{ background: '#fff', padding: '12px', border: 'none' }}>
                      <Link to={`/product/${items.store_id}/${functionIdLink(items?.product_id, items?.pid, items?.store_id)}/${productTitle(items.title)}`}>
                        <img className='op-item-img' style={{ borderRadius: '100px', border: '1px solid #eee' }} src={items.image} />
                      </Link>
                      <div className='op-item-title'>
                        <Link to={`/product/${items.store_id}/${functionIdLink(items?.product_id, items?.pid, items?.store_id)}/${productTitle(items.title)}`}>
                          <p style={{ margin: 0 }}>{items.title?.replace(/[^\w\s.,/:']/gi, ' ')}</p>
                        </Link>
                        <div className='op-item-content' style={{ padding: '5px 0px 0px 0px' }}>
                          <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', flexWrap: 'wrap' }}>
                            <Tag>Condition: {items.conditions}</Tag>
                            <Tag>Qty.: {items.quantity}</Tag>
                          </div>
                        </div>
                        {items.current_variant &&
                          <div style={{ padding: '5px 0px 0px 0px', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                            {Object.entries(items.current_variant || []).map(([key, value], index) => (
                              <Tag style={{ fontSize: '10px' }} key={index}><strong>{key}:</strong> {value}</Tag>
                            ))}
                          </div>
                        }
                      </div>
                    </div>
                    <div className='oip-f-jc-c'>
                      <div>
                        {items.state !== 'cancelled' &&
                          <>
                            <IconButton
                              className='oip-f-button'
                              style={{ minHeight: '30px', padding: '0px 10px 0px 10px', borderRadius: '10px' }}
                              size='sm'
                              variant='outlined'
                              onClick={(() => openTracking(isExpand === `open${items.id}` ? '' : `open${items.id}`))}>
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                <span style={{ fontSize: '13px' }}>Item Tracking</span>
                                {isExpand === `open${items.id}` && <ExpandLessIcon style={{ fontSize: '22px' }} />}
                                {isExpand !== `open${items.id}` && <ExpandMoreIcon style={{ fontSize: '22px' }} />}
                              </div>
                            </IconButton>

                            {isExpand === `open${items.id}` &&
                              <Stepper
                                style={{ marginTop: '20px' }}
                                orientation="vertical"
                                sx={{
                                  '--Stepper-verticalGap': '1.2rem',
                                  '--StepIndicator-size': '1.2rem',
                                  '--Step-gap': '1rem',
                                  '--Step-connectorInset': '0.1rem',
                                  '--Step-connectorRadius': '1rem',
                                  '--Step-connectorThickness': '3px',
                                  '--joy-palette-success-solidBg': 'var(--joy-palette-success-400)',

                                  [`& .${stepClasses.completed}`]: {
                                    '&::after': { bgcolor: 'success.solidBg' },
                                  },
                                  [`& .${stepClasses.active}`]: {
                                    [`& .${stepIndicatorClasses.root}`]: {
                                      border: '2px solid',
                                      borderColor: '#fff',
                                      boxShadow: (theme) => `0 0 0 1px ${theme.vars.palette.primary[500]}`,
                                    },
                                  },
                                  [`& .${stepClasses.disabled} *`]: {
                                    color: 'neutral.softDisabledColor',
                                  },
                                  [`& .${typographyClasses['title-sm']}`]: {
                                    textTransform: 'uppercase',
                                    letterSpacing: '1px',
                                    fontSize: '10px',
                                  },
                                }}
                              >
                                <Step
                                  active={items.shipping_state === 'processing'}
                                  completed={items.shipping_status?.split(',')?.includes('dispatched')}
                                  indicator={
                                    <StepIndicator
                                      variant={items.shipping_status?.split(',')?.includes('dispatched') ? 'solid' : 'solid'}
                                      color={items.shipping_status?.split(',')?.includes('dispatched') ? 'success' : items.shipping_state === 'processing' ? 'primary' : 'neutral'}>
                                      {items.shipping_status?.split(',')?.includes('dispatched') ?
                                        <CheckCircleRoundedIcon style={{ fontSize: '14px' }} /> :
                                        <span style={{ fontSize: '12px' }}>1</span>
                                      }
                                    </StepIndicator>
                                  }
                                >
                                  <div style={{ fontSize: '13px' }}>
                                    <Typography level="title-sm">Estimated 1 day</Typography>
                                    Processing
                                  </div>
                                </Step>

                                <Step
                                  disabled={!items.shipping_status?.split(',')?.includes('dispatched')}
                                  active={items.shipping_state === 'dispatched'}
                                  completed={getShippingStep(items.shipping_status)}
                                  indicator={
                                    <StepIndicator
                                      variant={items.shipping_status?.split(',')?.includes('dispatched') ? 'solid' : 'outlined'}
                                      color={getShippingStep(items.shipping_status) ? 'success' : items.shipping_state === 'dispatched' ? 'primary' : 'neutral'}>
                                      {getShippingStep(items.shipping_status) ?
                                        <CheckCircleRoundedIcon style={{ fontSize: '15px' }} /> :
                                        <span style={{ fontSize: '12px' }}>2</span>
                                      }
                                    </StepIndicator>
                                  }
                                >
                                  <div style={{ fontSize: '13px' }}>
                                    <Typography level="title-sm">
                                      {items.country === 'AE' ? 'Estimated 1-2 days' : 'Estimated 2-3 days'}
                                    </Typography>
                                    Procuring
                                  </div>
                                </Step>

                                <Step
                                  disabled={!items.shipping_status?.split(',')?.includes('dispatched')}
                                  active={items.shipping_state === 'dispatched'}
                                  completed={getShippingStep(items.shipping_status)}
                                  indicator={
                                    <StepIndicator
                                      variant={getShippingStep(items.shipping_status) ? 'solid' : 'outlined'}
                                      color={getShippingStep(items.shipping_status) ? 'success' : items.shipping_state === 'dispatched' ? 'primary' : 'neutral'}>
                                      {getShippingStep(items.shipping_status) ?
                                        <CheckCircleRoundedIcon style={{ fontSize: '14px' }} /> :
                                        <FiberManualRecordIcon style={{ fontSize: '14px' }} />
                                      }
                                    </StepIndicator>}>

                                  <div style={{ fontSize: '13px' }}>
                                    Dsipatched from Supplier
                                  </div>
                                </Step>


                                <Step
                                  disabled={!items.shipping_status?.split(',')?.includes(`arrived_in_${items.country}`)}
                                  active={getShippingStep1(items.shipping_state)}
                                  completed={items.shipping_status?.split(',')?.includes('delivered')}
                                  indicator={
                                    <StepIndicator
                                      variant={getShippingStep(items.shipping_status) ? 'solid' : 'outlined'}
                                      color={items.shipping_status?.split(',')?.includes('delivered') ? 'success' : getShippingStep(items.shipping_status) ? 'primary' : 'neutral'}>
                                      {items.shipping_status?.split(',')?.includes('delivered') ?
                                        <CheckCircleRoundedIcon style={{ fontSize: '14px' }} /> :
                                        <span style={{ fontSize: '12px' }}>3</span>
                                      }
                                    </StepIndicator>}>
                                  <div style={{ fontSize: '13px' }}>
                                    <Typography level="title-sm">
                                      {items.country === 'AE' ? 'Estimated 1-2 days' : 'Estimated 3-5 days'}
                                    </Typography>
                                    Shipping and Fulfillment
                                  </div>
                                </Step>
                                {items.country !== 'AE' &&
                                  <>
                                    <Step
                                      disabled={!items.shipping_status?.split(',')?.includes(`arrived_in_${items.country}`)}
                                      active={items.shipping_state === `arrived_in_${items.country}`}
                                      completed={items.shipping_status?.split(',')?.includes(`out_for_delivery`)}
                                      indicator={
                                        <StepIndicator
                                          variant={items.shipping_status?.split(',')?.includes(`departed_from_${items.country}`) ? 'solid' : 'outlined'}
                                          color={items.shipping_status?.split(',')?.includes(`out_for_delivery`) ? 'success' : items.shipping_status?.split(',')?.includes(`arrived_in_${items.country}`) ? 'primary' : 'neutral'}>
                                          {items.shipping_status?.split(',')?.includes(`departed_from_${items.country}`) ?
                                            <CheckCircleRoundedIcon style={{ fontSize: '14px' }} /> :
                                            <FiberManualRecordIcon style={{ fontSize: '14px' }} />
                                          }
                                        </StepIndicator>}>
                                      <div style={{ fontSize: '13px' }}>
                                        Arrived in {items.country} warehouse
                                      </div>
                                    </Step>

                                    <Step
                                      disabled={!items.shipping_status?.split(',')?.includes(`departed_from_${items.country}`)}
                                      active={items.shipping_state === `departed_from_${items.country}`}
                                      completed={items.shipping_status?.split(',')?.includes(`out_for_delivery`)}
                                      indicator={
                                        <StepIndicator
                                          variant={items.shipping_status?.split(',')?.includes(`arrived_in_uae`) ? 'solid' : 'outlined'}
                                          color={items.shipping_status?.split(',')?.includes(`out_for_delivery`) ? 'success' : items.shipping_status?.split(',')?.includes(`departed_from_${items.country}`) ? 'primary' : 'neutral'}>
                                          {items.shipping_status?.split(',')?.includes(`arrived_in_uae`) ?
                                            <CheckCircleRoundedIcon style={{ fontSize: '14px' }} /> :
                                            <FiberManualRecordIcon style={{ fontSize: '14px' }} />
                                          }
                                        </StepIndicator>}>
                                      <div style={{ fontSize: '13px' }}>
                                        Departed from {items.country} warehouse
                                      </div>
                                    </Step>
                                  </>
                                }
                                <Step
                                  disabled={!items.shipping_status?.split(',')?.includes(`arrived_in_uae`)}
                                  active={items.shipping_state === `arrived_in_uae`}
                                  completed={items.shipping_status?.split(',')?.includes(`out_for_delivery`)}
                                  indicator={
                                    <StepIndicator
                                      variant={items.shipping_status?.split(',')?.includes(`departed_from_uae`) ? 'solid' : 'outlined'}
                                      color={items.shipping_status?.split(',')?.includes(`out_for_delivery`) ? 'success' : items.shipping_status?.split(',')?.includes(`arrived_in_uae`) ? 'primary' : 'neutral'}>
                                      {items.shipping_status?.split(',')?.includes(`departed_from_uae`) ?
                                        <CheckCircleRoundedIcon style={{ fontSize: '14px' }} /> :
                                        <FiberManualRecordIcon style={{ fontSize: '14px' }} />
                                      }
                                    </StepIndicator>}>
                                  <div style={{ fontSize: '13px' }}>
                                    Arrived in UAE warehouse
                                  </div>
                                </Step>

                                <Step
                                  disabled={!items.shipping_status?.split(',')?.includes(`departed_from_uae`)}
                                  active={items.shipping_state === `departed_from_uae`}
                                  completed={items.shipping_status?.split(',')?.includes(`out_for_delivery`)}
                                  indicator={
                                    <StepIndicator
                                      variant={items.shipping_status?.split(',')?.includes(`out_for_delivery`) ? 'solid' : 'outlined'}
                                      color={items.shipping_status?.split(',')?.includes(`out_for_delivery`) ? 'success' : items.shipping_status?.split(',')?.includes(`departed_from_uae`) ? 'primary' : 'neutral'}>
                                      {items.shipping_status?.split(',')?.includes(`out_for_delivery`) ?
                                        <CheckCircleRoundedIcon style={{ fontSize: '14px' }} /> :
                                        <FiberManualRecordIcon style={{ fontSize: '14px' }} />
                                      }
                                    </StepIndicator>}>
                                  <div style={{ fontSize: '13px' }}>
                                    Departed from UAE warehouse
                                  </div>
                                </Step>

                                <Step
                                  disabled={!items.shipping_status?.split(',')?.includes(`out_for_delivery`)}
                                  active={items.shipping_state === `out_for_delivery`}
                                  completed={items.shipping_status?.split(',')?.includes(`delivered`)}
                                  indicator={
                                    <StepIndicator
                                      variant={items.shipping_status?.split(',')?.includes(`delivered`) ? 'solid' : 'outlined'}
                                      color={items.shipping_status?.split(',')?.includes(`delivered`) ? 'success' : items.shipping_status?.split(',')?.includes(`out_for_delivery`) ? 'primary' : 'neutral'}>
                                      {items.shipping_status?.split(',')?.includes(`delivered`) ?
                                        <CheckCircleRoundedIcon style={{ fontSize: '14px' }} /> :
                                        <FiberManualRecordIcon style={{ fontSize: '14px' }} />
                                      }
                                    </StepIndicator>}>
                                  <div style={{ fontSize: '13px' }}>
                                    Out for delivery
                                  </div>
                                </Step>
                                <Step
                                  disabled={!items.shipping_status?.split(',')?.includes(`delivered`)}
                                  // active={items.shipping_state === `delivered`}
                                  completed={items.shipping_status?.split(',')?.includes(`delivered`)}
                                  indicator={<StepIndicator
                                    variant={items.shipping_status?.split(',')?.includes(`delivered`) ? 'solid' : 'outlined'}
                                    color={items.shipping_status?.split(',')?.includes(`delivered`) ? 'success' : items.shipping_state === `delivered` ? 'primary' : 'neutral'}><LocalShippingRoundedIcon style={{ fontSize: '13px' }} /></StepIndicator>}>
                                  <div style={{ fontSize: '13px' }}>
                                    <Typography level="title-sm">
                                      {items.country === 'AE' ? 'Estimated 1-2 days' : 'Estimated 2-3 days'}
                                    </Typography>
                                    Delivered
                                  </div>
                                </Step>
                              </Stepper>
                            }
                          </>
                        }
                      </div>
                      <div className='oip-f-status'>
                        {items.state === 'cancelled' ?
                          <div className='badge badge-danger' style={{ marginBottom: '10px', fontSize: '11px', }}>Item has been cancelled</div>
                          :
                          <>
                            {items.delivered_on &&
                              <>
                                {items.shipping_state === 'delivered' &&
                                  <div style={{ fontSize: '1rem', whiteSpace: 'normal' }}>
                                    <span style={{ fontWeight: 400 }}>{t('DELIVERED ON')}&nbsp;{moment(items.delivered_on).format('Do MMM YYYY HH:mm')}</span>
                                  </div>
                                }
                              </>
                            }
                            {items.shipping_state !== 'delivered' &&
                              <div style={{ fontSize: '1rem', whiteSpace: 'normal' }}>
                                <span style={{ textTransform: 'uppercase' }}>{items.shipping_state}</span>
                              </div>
                            }
                          </>
                        }
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  }
  return (
    <>
      <HelmetComponent title={'Track order'} />
      <div className="Account-page tabel_cards">
        <br /><br />
        <div className="row" style={{ justifyContent: 'center', margin: '0px', padding: '0px' }}>
          <div className="col-md-10">

            <div className="row" style={{ justifyContent: 'center', width: '100%', margin: '0px', padding: '0px' }}>
              <div className="col-md-6">
                <div style={{ width: '100%', padding: '0px' }}>

                  {findresult?.error?.data?.error &&
                    <div className='alert alert-danger' style={{ textAlign: 'center', fontSize: '14px', padding: '10px', border: 'none' }}>
                      {findresult?.error?.data?.error}
                    </div>
                  }
                  {verifyresult?.error?.data?.error &&
                    <div className='alert alert-danger' style={{ textAlign: 'center', fontSize: '14px', padding: '10px', border: 'none' }}>
                      {verifyresult?.error?.data?.error}
                    </div>
                  }

                  {!verifyresult?.data?.order &&
                    <h3 style={{ textAlign: 'center', padding: '20px 0px 20px 0px', fontSize: '1.4rem', fontWeight: 400 }}>{t('Track your order')} </h3>
                  }

                  {isLoginCard &&
                    <form onSubmit={handleSubmit(trackOrder)} >
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey="6LfD62EnAAAAAJ83k0BznR8m_cXInxBFjtTokspL"
                      />
                      <div className="row" style={{ margin: '0px', padding: '0px' }}>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>{t('Order Number')} </label>
                            <input
                              style={{ background: '#f3f3f3', height: '60px' }}
                              type="text"
                              className="form-control"
                              id="order_number"
                              aria-invalid={errors.order_number ? "true" : "false"}
                              {...register('order_number', { required: true })} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>{t('email')} </label>
                            <input
                              style={{ background: '#f3f3f3', height: '60px' }}
                              type="email"
                              className="form-control"
                              id="email"
                              aria-invalid={errors.email ? "true" : "false"}
                              {...register('email', { required: true })} />
                          </div>
                        </div>
                        <div className="col-md-12 Header-signin-button">
                          <div className="form-group">
                            <Button
                              disabled={findresult.isLoading}
                              style={{ height: '55px', background: Host?.bgColor, color: Host?.buttonColor, width: '100%', textTransform: 'capitalize' }}
                              variant="info"
                              type="submit">
                              <span style={fontFamily}>
                                {
                                  findresult.isLoading ?
                                    <Loader /> :
                                    t('submit')
                                }
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </form>
                  }
                  {isOtpCard &&
                    <form onSubmit={handleSubmit2(loginWithOtp)}>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey="6LfD62EnAAAAAJ83k0BznR8m_cXInxBFjtTokspL"
                      />
                      <div className="col-md-12">
                        <div className="form-group" style={{ textAlign: 'center', padding: '15px 0px 15px 0px' }}>
                          <label style={{ textAlign: 'center' }}>{t('Enter your OTP')} </label>
                          <OtpInput
                            inputStyle="inputStyle"
                            containerStyle="otp otp-verify"
                            value={otp}
                            onChange={setOtp}
                            numInputs={6}
                            renderSeparator={<div style={{ padding: '5px' }}></div>}
                            id="code"
                            renderInput={(props) => <input {...props} />}
                          />
                        </div>
                      </div>
                      <div className="col-md-12 Header-signin-button">
                        <div className="form-group" style={{ textAlign: 'center' }}>
                          <Button
                            type='submit'
                            disabled={verifyresult?.isLoading}
                            variant="info"
                            style={{ height: '55px', background: Host?.bgColor, color: Host?.buttonColor, width: 'auto', textTransform: 'capitalize' }}
                          >
                            <span style={fontFamily}>
                              {
                                verifyresult?.isLoading ?
                                  <Loader /> :
                                  t('submit')
                              }
                            </span>
                          </Button>
                        </div>
                      </div>
                    </form>
                  }
                </div>
              </div>



              {verifyresult?.data?.order &&
                <>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <div className="col-md-12">
                      <h3 style={{ textAlign: 'center', padding: '20px 0px 20px 0px', fontSize: '1.5rem', fontWeight: 400 }}>{t('Order Details')} </h3>
                    </div>
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'left', flexWrap: 'wrap', flexDirection: 'column-reverse' }}>
                    <div className="col-md-8">
                      {getitesmData(verifyresult?.data?.order)}
                    </div>
                    <div className="col-md-4">
                      <div className='op-card-header pg-m-center' style={{ textAlign: 'right', border: '1px solid #eee', borderRadius: '10px', padding: '15px', background: '#efefef3b', }}>
                        <div className='op-o-info' style={{ fontSize: '1.1rem' }}>
                          <div style={{ marginBottom: '6px' }}>
                            <span style={{ fontWeight: 500 }}>Order Number:&nbsp;</span>{verifyresult?.data?.order?.order_number}</div>
                          <div style={{ marginBottom: '6px' }}>
                            <span style={{ fontWeight: 500 }}>Total Items:&nbsp;</span>{verifyresult?.data?.order?.order_items?.length}</div>
                        </div>
                        <div className='op-o-info' style={{ fontSize: '1.1rem' }}>
                          <div style={{ marginBottom: '6px' }}>
                            <span style={{ fontWeight: 500 }}>Order Status:&nbsp;</span>
                            {verifyresult?.data?.order?.states === 'cancelled' ? <span className='badge badge-danger'>Cancelled</span> :
                              <>
                                {verifyresult?.data?.order?.status ?
                                  <span className='badge badge-success'>Confirmed</span> :
                                  <span className='badge badge-warning'>Payment pending</span>
                                }
                              </>
                            }
                          </div>
                          <div style={{ marginBottom: '6px' }}>
                            <span style={{ fontWeight: 500 }}>{t('order_placed_on')}:&nbsp;</span> {moment(verifyresult?.data?.order?.createdAt).format("MMM Do YYYY")}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              }

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TrackOrder;
